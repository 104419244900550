<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "init",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "为什么要初始环境",
                        text:[
                            '因为助手是根据"图形识别"来判断任务并操作角色的,所以要有一套标准的"游戏设定",' +
                            '而初始环境就是助手帮助你设置好这一套标准的"游戏设定"',
                        ],
                        img:[]
                    },
                    {
                        title: "什么情况下要初始环境",
                        text:[
                            '1.任何"新角色"第一次使用PC助手,例如:你有A和B两个号,A用完PC助手并初始环境后,B上号了也必须要初始环境!',
                            '2.在使用助手任何"功能异常"的情况下,要再次初始环境,例如:非战斗可招架和寻路自动上马这两个设定是一些用户的习惯,' +
                            '但是这个设定会影响助手完成任务,所以需要初始环境帮你设置'
                        ],
                        img:[]
                    },
                    {
                        title: "初始环境操作",
                        text:[
                            '#在看完"新手教程",扫码登录后,助手处于图1界面,然后点击设置',
                            '#进入设置后,点击"初始环境", 助手在短暂的启动中后便开始初始环境设定',
                            '#成功:日志显示图3即表示初始环境成功!可以正常使用助手里的功能,具体功能可以看指定的教程',
                            '#失败:日志显示图4即表示初始环境失败!同时有消息框提示在哪一步失败了(图5),如果失败,请继续看教程!',
                            '*注意:请勿在活动场景"初始环境", 例如金乌岛,门禁,凌霄城,心魔等场景,推荐在各大主城'
                        ],
                        img:[
                            "0.png",
                            "1.png",
                            "2.png",
                            "3.png",
                            "4.png",
                        ]
                    },
                    {
                        title: "检查界面缩放",
                        text:[
                            '#游戏按"ESC"打开设置,点击"个性设置",点击"其他",勾选"界面缩放"并且后面的比例是1.0',
                            '#设置好后,回到上一步再次"初始环境",如果再次失败,请继续看教程!'
                        ],
                        img:[
                            "5.png",
                        ]
                    },
                    {
                        title: "检查点说明",
                        text:[
                            '#"初始环境"在设定游戏前,对画面、快捷键等做识别判断,有一些需要玩家手动解决,图中列出各个检查点,请依次检查',
                            '#图1,重要设定,需要切换到图中标识',
                            '#图2,小地图设定,"坐标"和"缩放"需要显示,游戏有一个bug,过场景后小地图会消失,返回人物一下即可恢复',
                            '#图3,背包设定,打开背包使用的快捷键是"B(默认)",如果背包快捷键是B,但是提示失败,可以移动一下背包位置,' +
                            '或者查看背包图标是否完全显示!',
                            '#图4,江湖助力设定,打开江湖助力的快捷键是"N(默认)",这一步失败大多数情况是个性设置-其他-界面缩放没有勾选或者' +
                            '没有设置1.0',
                            '#图5,落脚点设定,打开落脚点的快捷键是"T(默认)",这一步基本没有失败的情况,除非"回城"两字被遮挡,金乌岛没有回城所以' +
                            '会失败!',
                            '#图6,门派设定,打开门派的快捷键是"J(默认)",这一步基本没有失败的情况',
                            '#以上检查设置好后,回到第三步再次"初始环境",如果再次失败,请继续看教程!'
                        ],
                        img:[
                            "6.png",
                            "7.png",
                            "8.png",
                            "9.png",
                            "10.png",
                            "11.png",
                        ]
                    },
                    {
                        title: "修改电脑分辨率",
                        text:[
                            '#以上都失败了,那就是分辨率的问题了,退出游戏,切换到电脑桌面',
                            "#桌面右键点击选择显示设置(图1win10为例),按图2设置,1920*1080分辨率缩放100%,并且不能有分屏!",
                            '*注意:一般4K2K显示器、笔记本的界面缩放不是"100%",需要改为"100%",原因:此处甩锅给游戏,' +
                            '因为游戏一旦不是1920*1080,100%缩放的分辨率图形就会变形,导致不能识别!',

                        ],
                        img:[
                            "12.png",
                            "13.png",
                        ]
                    },
                    {
                        title: "游戏客户端设置",
                        text:[
                            '#分辨率设置好后,打开游戏,不要直接进入游戏!',
                            '#点击"游戏设置",按图1设置好,分辨率是1920*1040以上,打开"经典"模式',
                            '#最后进入游戏,回到第三步再次"初始环境",如果再次失败,请联系客服',
                        ],
                        img:[
                            "14.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
